import React from 'react'
import cx from 'classnames'
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from 'react-parallax-mouse'

import { StaticImage } from 'gatsby-plugin-image'
import { Button } from '~/components/Button/Button'
import { H2, P } from '~/components/Typography/Headings'

import * as styles from './thirdHome.module.scss'

const ThirdHome = ({ ...rest }) => {
  return (
    <section
      id="section3"
      className={cx(styles.homeSection, styles.thirdHome, 'slide')}
      {...rest}
    >
      <div
        className={cx(styles.homeContainer, styles.thirdHomeContainer)}
        data-aos="fade-in"
      >
        <div className={styles.thirdHomeIllstr}>
          <MouseParallaxContainer
            useWindowMouseEvents={true}
            resetOnLeave={true}
            className={styles.parallaxContainer}
          >
            <MouseParallaxChild factorX={0.01} factorY={0.03}>
              <StaticImage
                className={styles.thirdHomeIllstrHero1}
                src="../../../images/third-section/1hero.png"
                alt="Hero 1"
                placeholder="none"
              />
            </MouseParallaxChild>
            <MouseParallaxChild factorX={0.02} factorY={0.02}>
              <StaticImage
                className={styles.thirdHomeIllstrHero2}
                src="../../../images/third-section/2hero.png"
                alt="Hero 2"
                placeholder="none"
              />
            </MouseParallaxChild>
          </MouseParallaxContainer>
          <div className={styles.thirdHomeIllstrShield}></div>
        </div>
        <div className={styles.thirdHomeText}>
          <H2 data-aos="fade-up" data-aos-delay="0">
            {' '}
            Provably Fair Smart Contracts{' '}
          </H2>
          <P
            data-aos="fade-up"
            data-aos-delay="50"
            className={styles.thirdHomeSubtitle}
          >
            For each duel, the players will interact with smart contracts on the
            Harmony Blockchain. All duels and custody of funds are executed
            through smart contracts, as such we do not have any input on the
            results. We cannot influence the outcome of the game in advance or
            during the game itself. <b>Only the strongest will win!</b>
          </P>
          <a href="https://beta.dfkarena.com">
            <Button
              data-aos="fade-up"
              data-aos-delay="100"
              className={styles.thirdHomeBtn}
            >
              Start Now
            </Button>
          </a>
        </div>
      </div>
    </section>
  )
}

export default ThirdHome
