import React from 'react'
import cx from 'classnames'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { StaticImage } from 'gatsby-plugin-image'
import { H2, H3, P } from '~/components/Typography/Headings'

import * as styles from './fourthHome.module.scss'

const FourthHome = ({...rest}) => {
  gsap.registerPlugin(ScrollTrigger)

  const boxRef = React.useRef<HTMLDivElement>()

  const [anim, setAnim] = React.useState(styles.visibleFirst)

  React.useEffect(() => {
    ScrollTrigger.create({
      trigger: boxRef.current,
      start: 'top top',
      end: 'bottom bottom',
      onUpdate: (self) => {
        const progress = self.progress
        if (progress < 0.25) {
          setAnim(styles.visibleFirst)
        }
        if (progress > 0.25 && progress < 0.5) {
          setAnim(styles.visibleSecond)
        }
        if (progress > 0.5 && progress < 0.75) {
          setAnim(styles.visibleThird)
        }
        if (progress > 0.75) {
          setAnim(styles.visibleFourth)
        }
      },
    })
  }, [])

  return (
    <div id='section4' className={styles.scrollerContainer} ref={boxRef} {...rest}>
      <section className={cx(styles.homeSection, styles.fourthHome)}>
        <div className={cx(styles.homeContainer, styles.fourthHomeContainer)}>
          <H2 data-aos="fade-up" className={styles.fourthHomeTitle}>
            How Does it Work?
          </H2>

          <div className={cx(styles.fourthHomeSlider, anim)}>
            <div className={cx(styles.fourthHomeSlide, 'howSlide1')}>
              <H3 data-aos="fade-in" className={styles.fourthHomeSlideTitle}>
                Choose A hero
              </H3>
              <P
                data-aos="fade-in"
                data-aos-delay="200"
                className={styles.fourthHomeSlideText}
              >
                To start fighting in the arena you need to have at least one{' '}
                <br /> hero NFT from the DefiKingdoms world.
              </P>
              <StaticImage
                placeholder="none"
                className={cx(
                  styles.fourthHomeSlideIllstr,
                  styles.fourthHomeSlideIllstrFirst,
                )}
                src="../../../images/fourth-section/slide1.png"
                alt="Slide 1"
              />
              <StaticImage
                placeholder="none"
                className={cx(
                  styles.fourthHomeSlideIllstrMobile,
                  styles.fourthHomeSlideIllstrMobileFirst,
                )}
                src="../../../images/fourth-section/slide1-mobile.png"
                alt="Slide 1"
              />
            </div>
            <div className={cx(styles.fourthHomeSlide, 'howSlide2')}>
              <H3 className={styles.fourthHomeSlideTitle}>
                FIND or create game
              </H3>
              <P className={styles.fourthHomeSlideText}>
                Select your character and create a new duel, or you can <br />{' '}
                simply join an existing duel.
              </P>
              <StaticImage
                className={cx(
                  styles.fourthHomeSlideIllstr,
                  styles.fourthHomeSlideIllstrSecond,
                )}
                src="../../../images/fourth-section/slide2.png"
                alt="Slide 2"
                placeholder="none"
              />
              <StaticImage
                placeholder="none"
                className={cx(
                  styles.fourthHomeSlideIllstrMobile,
                  styles.fourthHomeSlideIllstrMobileSecond,
                )}
                src="../../../images/fourth-section/slide2-mobile.png"
                alt="Slide 2"
              />
            </div>
            <div className={cx(styles.fourthHomeSlide, 'howSlide3')}>
              <H3 className={styles.fourthHomeSlideTitle}>
                JOIN THE REAL FIGHT
              </H3>
              <P className={styles.fourthHomeSlideText}>
                Winners keep 90% of the gold bet on each side. <br /> Each hero
                can battle once every 4 hours.
              </P>
              <StaticImage
                className={cx(
                  styles.fourthHomeSlideIllstr,
                  styles.fourthHomeSlideIllstrThird,
                )}
                src="../../../images/fourth-section/slide3.png"
                alt="Slide 3"
                placeholder="none"
              />
              <StaticImage
                placeholder="none"
                className={cx(
                  styles.fourthHomeSlideIllstrMobile,
                  styles.fourthHomeSlideIllstrMobileThird,
                )}
                src="../../../images/fourth-section/slide3-mobile.png"
                alt="Slide 3"
              />
            </div>
            <div className={cx(styles.fourthHomeSlide, 'howSlide4')}>
              <H3 className={styles.fourthHomeSlideTitle}>earn rewards!</H3>
              <P className={styles.fourthHomeSlideText}>
                Duel your strongest heroes against random opponents. <br /> Only
                the victorious will reap the rewards!
              </P>
              <StaticImage
                className={cx(
                  styles.fourthHomeSlideIllstr,
                  styles.fourthHomeSlideIllstrFourth,
                )}
                src="../../../images/fourth-section/slide4.png"
                alt="Slide 4"
                placeholder="none"
              />
              <StaticImage
                placeholder="none"
                className={cx(
                  styles.fourthHomeSlideIllstrMobile,
                  styles.fourthHomeSlideIllstrMobileFourth,
                )}
                src="../../../images/fourth-section/slide4-mobile.png"
                alt="Slide 4"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default FourthHome
