import React from 'react'
import cx from 'classnames'

import * as styles from './Button.module.scss'

export type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export const Button = ({ children, className, ...rest }: Props) => {
  return (
    <button className={cx(styles.base, className)} {...rest}>
      {children}
    </button>
  )
}
