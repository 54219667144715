import React from 'react'
import cx from 'classnames'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { StaticImage } from 'gatsby-plugin-image'
import { H2 } from '~/components/Typography/Headings'

import * as styles from './secondHome.module.scss'

function getImgUrl(imageName: string) {
  return `/frames/${imageName}.png`
}

function getZerosByLength(length: number) {
  switch (length) {
    case 1:
      return '000'
    case 2:
      return '00'
    default:
      return '0'
  }
}

const getFrameIndex = (index: number) => (index / 2) | 0

function preloadCacheFrames() {
  const images: HTMLImageElement[] = []
  for (let i = 1; i < 252; i++) {
    const index = i * 2
    const zeros = getZerosByLength(index.toString().length)
    const src = getImgUrl(`${zeros}${index}`)
    const img = new Image()
    img.src = src
    images.push(img)
  }
  return images
}

const firstImage = getImgUrl('0001')

const SecondHome = () => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const animationRef = React.useRef<HTMLDivElement>(null)

  const [animationStart, setAnimationStart] = React.useState(false)
  const [healthAnimation, setHealthAnimation] = React.useState(false)
  const [counterTime, setCounterTime] = React.useState('3')
  // const [images, setImages] = React.useState([])
  const [healthAmount, setHealthAmount] = React.useState(20)

  React.useEffect(() => {
    const images = preloadCacheFrames()

    // scrolltrigger gsap
    gsap.registerPlugin(ScrollTrigger)

    let scene = {
      frame: 1,
    }

    let pt1Scene = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: 'top top',
        end: 'bottom bottom',
        scrub: 0,
        onUpdate: (e) => {
          if (e.progress < 0.05) {
            setCounterTime('3')
          } else if (e.progress < 0.1) {
            setCounterTime('2')
          } else if (e.progress < 0.15) {
            setCounterTime('1')
          } else if (e.progress < 0.2) {
            setCounterTime('fight')
          } else if (e.progress > 0.25) {
            setCounterTime('')
            setAnimationStart(true)
          }
          if (e.progress <= 0.25) {
            setAnimationStart(false)
          }
          if (e.progress > 0.69) {
            setHealthAmount(0)
            setHealthAnimation(true)
          } else {
            setHealthAmount(20)
            setHealthAnimation(false)
          }
        },
      },
    })

    pt1Scene.to(
      scene,
      {
        frame: 252,
        snap: 'frame',
        onUpdate: render,
      },
      '+=25%',
    )

    images[scene.frame].onload = () => {
      requestAnimationFrame(render)
    }

    function render() {
      const image = images[scene.frame]
      if (!animationRef.current || !image) {
        return
      }
      animationRef.current.style.background = `url(${image.src}) no-repeat center / contain`
    }
  }, [])

  return (
    <div
      className={styles.scrollContainer}
      id="secondTrigger"
      ref={containerRef}
    >
      <div className={cx(styles.homeSection, styles.secondHome, 'slide')}>
        <div className={styles.homeContainer}>
          <div className={styles.secondHomeBar}>
            <div className={styles.secondHomeBarPlayer}>
              <div className={cx(styles.secondHomeBarPlayerName, styles.isPre)}>
                Priestly Grassbrook
              </div>
              <StaticImage
                className={styles.secondHomeBarPlayerIcon}
                src="../../../images/second-section/health-icon.png"
                alt="Health icon"
                placeholder="none"
              />
              <div className={styles.secondHomeBarPlayerProgress}>
                <span>30</span>
                <span style={{ width: '30%' }}></span>
              </div>
            </div>
            <div className={styles.secondHomeBarTimer}>
              <H2 className={styles.secondHomeBarTimerText}>90</H2>
            </div>
            <div className={styles.secondHomeBarPlayer}>
              <div className={styles.secondHomeBarPlayerName}>
                Gadreel Cedarslumber
              </div>
              <StaticImage
                className={cx(styles.secondHomeBarPlayerIcon, styles.isPost)}
                src="../../../images/second-section/health-icon.png"
                alt="Health icon"
                placeholder="none"
              />
              <div
                className={cx(
                  styles.secondHomeBarPlayerProgress,
                  styles.isPost,
                )}
              >
                <span>{healthAmount}</span>
                <span style={{ width: healthAmount + '%' }}></span>
              </div>
            </div>
          </div>
          <H2 className={styles.secondHomeCountdown}>{counterTime}</H2>
          <div className={styles.secondHomeIllstrContainer}>
            <span
              className={cx(
                styles.secondHomeIllstrWrapper,
                healthAnimation ? styles.animated : '',
              )}
            >
              <div
                ref={animationRef}
                className={cx(
                  styles.secondHomeAnimation,
                  animationStart ? '' : styles.hidden,
                )}
              />

              <img
                className={cx(
                  styles.secondHomeIllstr,
                  animationStart ? styles.hidden : '',
                )}
                src={firstImage}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecondHome
