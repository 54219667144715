import React from 'react'
import cx from 'classnames'

import '@fontsource/lora/500.css'
import '@fontsource/lora/700.css'

import * as styles from './Headings.module.scss'

export const H1 = ({ children, className, ...rest }) => {
  return (
    <h1 className={cx(styles.homeTitle, styles.homeH1, className)} {...rest}>
      <span>{children}</span>
    </h1>
  )
}

export const H2 = ({ children, className, ...rest }) => {
  return (
    <h2 className={cx(styles.homeTitle, styles.homeH2, className)} {...rest}>
      <span>{children}</span>
    </h2>
  )
}

export const H3 = ({ children, className, ...rest }) => {
  return (
    <h3 className={cx(styles.homeTitle, styles.homeH3, className)} {...rest}>
      <span>{children}</span>
    </h3>
  )
}

export const P = ({ children, className, ...rest }) => {
  return (
    <p className={cx(styles.homeP, className)} {...rest}>
      {children}
    </p>
  )
}
