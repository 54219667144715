import React from 'react'
import cx from 'classnames'
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from 'react-parallax-mouse'

import { StaticImage } from 'gatsby-plugin-image'
import { Button } from '~/components/Button/Button'
import { H1, P } from '~/components/Typography/Headings'

import * as styles from './firstHome.module.scss'

import illstr from '../../../images/first-section/heroes.png'

const FirstHome = () => {
  return (
    <section className={cx(styles.homeSection, styles.firstHome, 'slide')}>
      <div className={cx(styles.homeContainer, styles.firstHomeContainer)}>
        <div className={styles.firstHomeLogo} data-aos="fade-in">
          <StaticImage
            placeholder="none"
            src="../../../images/first-section/logo.png"
            alt="Logotype"
          />
        </div>
        <H1 className={styles.firstHomeTitle} data-aos="fade-up">
          Monetize your heroes by engaging in PVP fights!
        </H1>
        <P
          className={styles.firstHomeSubtitle}
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Animated Blockchain-Based Online PvP Battles for DefiKingdom Hero NFTs
        </P>
        <a style={{ textDecoration: 'none' }} href="https://beta.dfkarena.com">
          <Button
            className={styles.firstHomeBtn}
            data-aos="fade-up"
            data-aos-delay="400"
          >
            START FIGHTING!
          </Button>
        </a>
        <img src={illstr} className={styles.firstHomeHeroes} alt="Heroes" />
        <MouseParallaxContainer
          resetOnLeave={true}
          useWindowMouseEvents={true}
          className={styles.firstHomeParticles}
        >
          <MouseParallaxChild factorX={0.01} factorY={0.03}>
            <div
              className={cx(
                styles.firstHomeParticlesItem,
                styles.firstHomeParticlesItem1,
              )}
            >
              <StaticImage
                placeholder="none"
                className={styles.firstHomeParticlesItemImg}
                src="../../../images/first-section/particles/rarity-mythic.png"
                alt="mythic"
              />
            </div>
          </MouseParallaxChild>
          <MouseParallaxChild factorX={0.01} factorY={0.03}>
            <div
              className={cx(
                styles.firstHomeParticlesItem,
                styles.firstHomeParticlesItem2,
              )}
            >
              <StaticImage
                placeholder="none"
                className={styles.firstHomeParticlesItemImg}
                src="../../../images/first-section/particles/rarity-rare.png"
                alt="rare"
              />
            </div>
          </MouseParallaxChild>
          <MouseParallaxChild factorX={0.01} factorY={0.03}>
            <div
              className={cx(
                styles.firstHomeParticlesItem,
                styles.firstHomeParticlesItem3,
              )}
            >
              <StaticImage
                placeholder="none"
                className={styles.firstHomeParticlesItemImg}
                src="../../../images/first-section/particles/rarity-legendary.png"
                alt="legendary"
              />
            </div>
          </MouseParallaxChild>
        </MouseParallaxContainer>
      </div>
    </section>
  )
}

export default FirstHome
