import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'react-scroll'
import AOS from 'aos'
import 'aos/dist/aos.css'

// fonts
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'

// page basic styles
import * as styles from './styles/Index.module.scss'

// sections
import FirstHome from '../sections/home/first-home/firstHome'
import SecondHome from '../sections/home/second-home/secondHome'
import ThirdHome from '../sections/home/third-home/thirdHome'
import FourthHome from '../sections/home/fourth-home/fourthHome'
import FifthHome from '../sections/home/fifth-home/fifthHome'

const Index = () => {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false)
  function toggleBurger() {
    setShowBurgerMenu(!showBurgerMenu)
  }

  React.useEffect(() => {
    // aos
    AOS.init({
      duration: 1000,
      once: true,
    })
    AOS.refresh()
  }, [])

  return (
    <div className={styles.homePanels}>
      <div className={cx(styles.home, 'homeWrap')}>
        {/* Header */}
        <div className={styles.homeHeader}>
          <button className={styles.homeHeaderBurger} onClick={toggleBurger}>
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className={cx(styles.homeSocials, styles.homeHeaderSocials)}>
            <a
              href={'https://twitter.com/dfk_arena'}
              rel="nofollow"
              target="_blank"
              className={styles.homeSocialsLink}
            >
              <StaticImage
                placeholder="none"
                src="../images/socials/Twitter.svg"
                alt="Twitter"
              />
            </a>
            <a
              href={'https://discord.gg/PgSeuswq82'}
              rel="nofollow"
              target="_blank"
              className={styles.homeSocialsLink}
            >
              <StaticImage
                placeholder="none"
                src="../images/socials/discord.svg"
                alt="Discord"
              />
            </a>
          </div>
          <div className={styles.homeHeaderNav}>
            <Link
              to={'section3'}
              spy={true}
              smooth={true}
              className={styles.homeHeaderNavLink}
            >
              Fair Game
            </Link>
            <Link
              to={'section4'}
              spy={true}
              smooth={true}
              className={styles.homeHeaderNavLink}
            >
              How it works?
            </Link>
            <Link
              to={'section5'}
              spy={true}
              smooth={true}
              className={styles.homeHeaderNavLink}
            >
              newsletter
            </Link>
            <a
              href="https://beta.dfkarena.com"
              className={cx(
                styles.homeHeaderNavLink,
                styles.homeHeaderNavLinkWallet,
              )}
              title={'Start Now'}
            >
              Start Now
            </a>
          </div>
        </div>
        <div
          className={cx(
            styles.homeMenu,
            showBurgerMenu ? styles.homeMenuVisible : '',
          )}
        >
          <div className={styles.homeMenuInner}>
            <div className={styles.homeMenuTop}>
              <button
                className={styles.homeMenuClose}
                onClick={toggleBurger}
              ></button>
            </div>
            <div className={styles.homeMenuNav}>
              <Link
                onClick={() => {
                  toggleBurger()
                }}
                to={'section3'}
                spy={true}
                smooth={true}
                className={styles.homeMenuNavLink}
              >
                Fair Game
              </Link>
              <Link
                onClick={() => {
                  toggleBurger()
                }}
                to={'section4'}
                spy={true}
                smooth={true}
                className={styles.homeMenuNavLink}
              >
                How it works?
              </Link>
              <Link
                to={'section5'}
                spy={true}
                smooth={true}
                className={styles.homeMenuNavLink}
              >
                newsletter
              </Link>
            </div>
            <div className={cx(styles.homeSocials, styles.homeMenuSocials)}>
              <div className={styles.homeMenuSocialsRow}>
                <a
                  href={'https://twitter.com/dfk_arena'}
                  rel="nofollow"
                  target="_blank"
                  className={styles.homeSocialsLink}
                >
                  <StaticImage
                    placeholder="none"
                    src="../images/socials/Twitter.svg"
                    alt="Twitter"
                  />
                </a>
                <a
                  href={'https://discord.gg/PgSeuswq82'}
                  rel="nofollow"
                  target="_blank"
                  className={styles.homeSocialsLink}
                >
                  <StaticImage
                    placeholder="none"
                    src="../images/socials/discord.svg"
                    alt="Discord"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.homeMenuOverlay} onClick={toggleBurger}></div>
        </div>
        <FirstHome />
        <SecondHome />
        <ThirdHome />
        <FourthHome />
        <FifthHome />
      </div>
    </div>
  )
}

export default Index
