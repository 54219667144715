import React from 'react'
import cx from 'classnames'

import { StaticImage } from 'gatsby-plugin-image'
import { Button } from '~/components/Button/Button'
import { H2, P } from '~/components/Typography/Headings'

import * as styles from './fifthHome.module.scss'

const FifthHome = ({ ...rest }) => {
  return (
    <section
      id={'section5'}
      className={cx(styles.homeSection, styles.fifthHome, 'slide')}
      {...rest}
    >
      <div className={styles.fifthHomeWrapper}>
        <div
          className={cx(styles.homeContainer, styles.fifthHomeContainer)}
          data-aos="fade-in"
        >
          <div className={styles.fifthHomeIllstr}>
            <StaticImage
              placeholder="none"
              className={styles.fifthHomeIllstrImg}
              src="../../../images/fifth-section/illstr.png"
              alt="Illustration"
            />
          </div>
          <div className={styles.fifthHomeText}>
            <H2>Join the mailing list</H2>
            <P
              data-aos="fade-up"
              data-aos-delay="200"
              className={styles.fifthHomeSubtitle}
            >
              Stay up to date with updates from DFK Arena and be the first to{' '}
              <br /> get early access to releases.
            </P>
            <form
              className={styles.homeForm}
              data-aos="fade-in"
              data-aos-delay="400"
              method="post"
              name="mc-embedded-subscribe-form"
              target="_blank"
              noValidate
              action="https://gmail.us20.list-manage.com/subscribe/post?u=a5260efa3697edf063eb52d6a&amp;id=5fc9b2e664"
            >
              <input
                type="email"
                name="EMAIL"
                placeholder="Your email"
                required
                className={styles.homeFormInput}
              />
              <div
                style={{ position: 'absolute', left: '-5000px' }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_a5260efa3697edf063eb52d6a_5fc9b2e664"
                  tabIndex={-1}
                  value=""
                />
              </div>
              <Button className={styles.homeFormBtn}>SUBSCRIBE</Button>
            </form>
          </div>
        </div>
      </div>
      <footer className={styles.homeFooter}>
        <div className={styles.homeSocials}>
          <a
            href={'https://twitter.com/dfk_arena'}
            rel="nofollow"
            target="_blank"
            className={styles.homeSocialsLink}
          >
            <StaticImage
              placeholder="none"
              src="../../../images/socials/Twitter.svg"
              alt="Twitter"
            />
          </a>
          <a
            href={'https://discord.gg/PgSeuswq82'}
            rel="nofollow"
            target="_blank"
            className={styles.homeSocialsLink}
          >
            <StaticImage
              placeholder="none"
              src="../../../images/socials/discord.svg"
              alt="Discord"
            />
          </a>
        </div>
      </footer>
    </section>
  )
}

export default FifthHome
